import { INewsPrewiew } from "@/types";
import { NewsItem } from "../NewsItem/NewsItem";

export const NewsList: React.FC<{
  news: INewsPrewiew[];
  remove: (id: number, title: string) => void;
}> = ({ news, remove }) => {
  return (
    <>
      {news.map((item) => {
        return <NewsItem remove={remove} item={item} key={item.id} />;
      })}
    </>
  );
};
