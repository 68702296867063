import { tokenLSKey } from '@/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import {
  IAddCurator,
  ICurator,
  IRemoveDataResponse,
  ICuratorsResponse,
  IGetCuratorsQuery,
  IEditCurator,
} from '@/types';

export const curatorsAPI = createApi({
  reducerPath: 'curatorsAPI',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/curator',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);
      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ['Curators'],
  endpoints: (builder) => ({
    getAllCurators: builder.query<ICuratorsResponse, IGetCuratorsQuery>({
      query: (params) => ({
        url: 'get-all/',
        method: 'GET',
        params,
      }),
      providesTags: (result) => ['Curators'],
    }),
    getCuratorsList: builder.query<
      { id: number; name: string; login: string }[],
      null
    >({
      query: () => ({
        url: 'get-list/',
        method: 'GET',
      }),
      providesTags: (result) => ['Curators'],
    }),

    addCurator: builder.mutation<ICurator, IAddCurator>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Curators'],
    }),

    removeCurator: builder.mutation<IRemoveDataResponse, { id: number }>({
      query: (body) => ({
        url: '',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Curators'],
    }),
    editCurator: builder.mutation<ICurator, IEditCurator>({
      query: (body) => ({
        url: '',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Curators'],
    }),

    banCurator: builder.mutation<
      { message: string },
      { id: number; isBanned: boolean }
    >({
      query: (body) => ({
        url: '/ban',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Curators'],
    }),
  }),
});

export const {
  useAddCuratorMutation,
  useGetAllCuratorsQuery,
  useRemoveCuratorMutation,
  useBanCuratorMutation,
  useEditCuratorMutation,
  useGetCuratorsListQuery,
} = curatorsAPI;
