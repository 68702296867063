import { StatItem } from '@/components';
import { useGetStatsQuery } from '@/redux/api/statsAPI';

type Props = {
  type: 'webmastersTotalEarned' | 'curatorsTotalEarned' | 'totalPaid';
};

export const PaidOrEarnedItem: React.FC<Props> = ({ type }) => {
  const { data } = useGetStatsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  if (type === 'webmastersTotalEarned') {
    return (
      <StatItem
        title='Заработано вебмастерами'
        count={data?.webmastersTotalEarned}
        postfix='K'
      />
    );
  }

  if (type === 'curatorsTotalEarned') {
    return (
      <StatItem
        title='Заработано кураторами'
        count={data?.curatorsTotalEarned}
        postfix='K'
      />
    );
  }

  if (type === 'totalPaid') {
    return (
      <div className='stats rounded-md shadow theme-border'>
        <div className='stat'>
          <div className='stat-title'>Выплачено всего</div>
          <div className='stat-value'>{data?.totalPaid}</div>
        </div>

        <div className='stat'>
          <div className='stat-title'>Выплачено вебмастерам</div>
          <div className='stat-value'>
            {data?.webmastersTotalPaid}
            <span className='font-medium text-xl pl-2'>K</span>
          </div>
        </div>

        <div className='stat'>
          <div className='stat-title'>Выплачено кураторам</div>
          <div className='stat-value'>
            {data?.curatorsTotalPaid}
            <span className='font-medium text-xl pl-2'>K</span>
          </div>
        </div>

        <div className='stat'>
          <div className='stat-title'>Выплатить вебмастерам</div>
          <div className='stat-value'>
            {data?.waitingPayments.webmastersSum}
            <span className='font-medium text-xl pl-2'>K</span>
          </div>
        </div>

        <div className='stat'>
          <div className='stat-title'>Выплатить кураторам</div>
          <div className='stat-value'>
            {data?.waitingPayments.curatorsSum}
            <span className='font-medium text-xl pl-2'>K</span>
          </div>
        </div>
      </div>
    );
  }

  return <span></span>;
};
