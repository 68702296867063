import { Link } from "react-router-dom";

export const AddOffer = () => {
  return (
    <div className="bg-base-100 shadow theme-border rounded-md px-6 py-4">
      <div className="opacity-60">Добавить Оффер</div>
      <Link
        className="btn btn-success btn-sm mt-2  md:w-auto w-full"
        to="/cab/add-offer"
      >
        Добавить
      </Link>
    </div>
  );
};
