import "./PaginationNew.css";

type Props = {
  total: number;
  offset: number;
  limit: number;
  onChange: (p: number) => void;
  scroll?: true;
};

export const PaginationNew: React.FC<Props> = ({
  total,
  offset,
  limit,
  onChange,
  scroll,
}) => {
  const count = Math.ceil(total / limit);
  const current =
    offset === 0 ? 1 : count - Math.ceil((total - offset) / limit) + 1;
  const arr = Array.from({ length: count }, (_, i) => i + 1);

  const clickHandler = (p: number) => {
    const isScrollable = document.body.offsetHeight > window.innerHeight;
    if (scroll && isScrollable) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    if (current === p) return;
    setTimeout(() => onChange(p), 200);
  };

  return (
    <div className="btn-group">
      {count > 1 && (
        <>
          {arr[current - 4] && (
            <button
              className="pagination-btn btn-sm"
              onClick={() => clickHandler(current - 3)}
            >
              {current - 3}
            </button>
          )}
          {arr[current - 3] && (
            <button
              className="pagination-btn btn-sm"
              onClick={() => clickHandler(current - 2)}
            >
              {current - 2}
            </button>
          )}
          {arr[current - 2] && (
            <button
              className="pagination-btn btn-sm"
              onClick={() => clickHandler(current - 1)}
            >
              {current - 1}
            </button>
          )}

          <button className="pagination-btn btn-sm btn-active">
            {current}
          </button>

          {arr[current] && (
            <button
              className="pagination-btn btn-sm"
              onClick={() => clickHandler(current + 1)}
            >
              {current + 1}
            </button>
          )}
          {arr[current + 1] && (
            <button
              className="pagination-btn btn-sm"
              onClick={() => clickHandler(current + 2)}
            >
              {current + 2}
            </button>
          )}
          {arr[current + 2] && (
            <button
              className="pagination-btn btn-sm"
              onClick={() => clickHandler(current + 3)}
            >
              {current + 3}
            </button>
          )}
        </>
      )}
    </div>
  );
};
