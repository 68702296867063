import { useNavigate } from "react-router-dom";

import { routing, tokenLSKey } from "@/constants";
import { useAppDispatch } from "@/hooks/useRedux";
import { logout } from "@/redux/features/adminSlice";
import { LogoutIcon } from "../Icons/LogoutIcon";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logoutHandler = () => {
    localStorage.removeItem(tokenLSKey);
    navigate(`/${routing.LOGIN}`);
    dispatch(logout());
  };

  return (
    <div
      className="cursor-pointer tooltip tooltip-bottom"
      data-tip="выйти"
      onClick={logoutHandler}
    >
      <LogoutIcon />
    </div>
  );
};
