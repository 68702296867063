import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import {
  IAddNews,
  IEditNews,
  INews,
  INewsResponse,
  IRemoveDataResponse,
} from "@/types";

export const newsAPI = createApi({
  reducerPath: "newsAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/news",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["News"],
  endpoints: (builder) => ({
    getAllNews: builder.query<INewsResponse, { offset: number; limit: number }>(
      {
        query: ({ offset, limit }) => ({
          url: "",
          method: "GET",
          params: { offset, limit },
        }),
        providesTags: (result) => ["News"],
      }
    ),
    getNewsById: builder.query<INews, { id: number }>({
      query: ({ id }) => ({
        url: `${id}`,
        method: "GET",
      }),
      providesTags: (result) => ["News"],
    }),
    editNews: builder.mutation<INews, IEditNews>({
      query: (body) => ({
        url: `${body.id}`,
        method: "PATCH",
        body,
      }),
    }),
    addNews: builder.mutation<INews, IAddNews>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["News"],
    }),
    removeNews: builder.mutation<IRemoveDataResponse, { id: number }>({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["News"],
    }),
  }),
});

export const {
  useGetAllNewsQuery,
  useAddNewsMutation,
  useRemoveNewsMutation,
  useGetNewsByIdQuery,
  useEditNewsMutation,
} = newsAPI;
