import { MobileMenu } from "@/components";

import "./SecondNavBar.css";

export const SecondNavBar = () => {
  return (
    <div className="navbar bg-base-100 lg:hidden">
      <MobileMenu />
    </div>
  );
};
