import { RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  id?: number;
  isSuccessOpen: boolean;
  isInWorkOpen: boolean;
  isErrorOpen: boolean;
} = {
  id: undefined,
  isSuccessOpen: false,
  isInWorkOpen: false,
  isErrorOpen: false,
};

export const leadsModalsSlice = createSlice({
  initialState,
  name: "leadsModals",
  reducers: {
    setId: (state, action: PayloadAction<number | undefined>) => {
      state.id = action.payload;
    },

    setSuccessOpen: (state, action: PayloadAction<boolean>) => {
      state.isSuccessOpen = action.payload;
    },

    setWorkOpen: (state, action: PayloadAction<boolean>) => {
      state.isInWorkOpen = action.payload;
    },

    setErrorOpen: (state, action: PayloadAction<boolean>) => {
      state.isErrorOpen = action.payload;
    },
  },
});

export default leadsModalsSlice.reducer;

export const { setId, setSuccessOpen, setWorkOpen, setErrorOpen } =
  leadsModalsSlice.actions;
export const leadsModals = (state: RootState) => state.leadsModals;
