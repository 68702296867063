import { useEffect } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useBanWebmasterMutation } from "@/redux/api/webmastersAPI";
import { IErrorResponse } from "@/types";
import { PauseIcon, PlayIcon } from "@/components/";
import { useBanCuratorMutation } from "@/redux/api/curatorsAPI";

type Props = {
  id: number;
  isBanned: boolean;
  role: "webmaster" | "curator";
};

export const BanUserButton: React.FC<Props> = ({ id, isBanned, role }) => {
  const [setBannedWebmaster, { error: webmasterError, data: webmasterData }] =
    useBanWebmasterMutation();
  const [setBannedCurator, { error: curatorError, data: curatorData }] =
    useBanCuratorMutation();

  const banBtnHandler = () => {
    if (role === "webmaster") setBannedWebmaster({ isBanned: !isBanned, id });
    if (role === "curator") setBannedCurator({ isBanned: !isBanned, id });
  };

  useEffect(() => {
    if (!webmasterData) return;
    return notify.success(webmasterData.message);
  }, [webmasterData]);

  useEffect(() => {
    if (!webmasterError) return;
    const { data } = webmasterError as IErrorResponse;
    if (data) return notify.error(data.message);
    notify.error("Что-то пошло не так");
  }, [webmasterError]);

  useEffect(() => {
    if (!curatorData) return;
    return notify.success(curatorData.message);
  }, [curatorData]);

  useEffect(() => {
    if (!curatorError) return;
    const { data } = curatorError as IErrorResponse;
    if (data) return notify.error(data.message);
    notify.error("Что-то пошло не так");
  }, [curatorError]);

  return isBanned ? (
    <div className="tooltip" data-tip="разбанить">
      <button
        className="btn btn-success btn-xs capitalize"
        onClick={banBtnHandler}
      >
        <PlayIcon />
      </button>
    </div>
  ) : (
    <div className="tooltip" data-tip="забанить">
      <button
        className="btn btn-warning btn-xs capitalize"
        onClick={banBtnHandler}
      >
        <PauseIcon />
      </button>
    </div>
  );
};
