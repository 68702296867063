import {
  IConfirmCandidateModalState,
  IRegisterRequest,
  RootState,
} from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IConfirmCandidateModalState = {
  candidate: null,
  isOpen: false,
};

export const confirmReqistrationModalSlice = createSlice({
  initialState,
  name: "confirmReqistrationModal",
  reducers: {
    setConfirmWebmasterAndOpen: (
      state,
      action: PayloadAction<IRegisterRequest>
    ) => {
      state.isOpen = true;
      state.candidate = action.payload;
    },
    clearConfirmWebmasterAndClose: (state) => {
      state.isOpen = false;
      state.candidate = null;
    },
  },
});

export default confirmReqistrationModalSlice.reducer;

export const { setConfirmWebmasterAndOpen, clearConfirmWebmasterAndClose } =
  confirmReqistrationModalSlice.actions;
export const confirmReqistrationModal = (state: RootState) =>
  state.confirmReqistrationModal;
