import { useState, useCallback } from "react";

import { RemoveCategoryModal } from "@/components/";
import { ICategoriesTableData, IRemoveCategoryModalData } from "@/types";

type Props = {
  categories: ICategoriesTableData[];
};

export const CategoriesTable: React.FC<Props> = ({ categories }) => {
  const [removeCategoryModalData, setRemoveCategoryModalData] =
    useState<IRemoveCategoryModalData>({
      isOpen: false,
      id: null,
      name: null,
    });

  const openRemoveModalHandler = (id: number, name: string) => {
    setRemoveCategoryModalData({
      isOpen: true,
      id,
      name,
    });
  };

  const closeRemoveModalHandler = useCallback(() => {
    setRemoveCategoryModalData({
      isOpen: false,
      id: null,
      name: null,
    });
  }, []);

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Офферов</th>
              <th>Управление</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((cat) => {
              return (
                <tr className="hover" key={cat.id}>
                  <td>{cat.id}</td>
                  <td>{cat.name}</td>

                  <td className="font-medium">{cat.offers}</td>
                  <td>
                    <div className="flex gap-2">
                      <button
                        className={`btn btn-error  btn-xs capitalize ${
                          cat.offers ? "btn-disabled" : ""
                        }`}
                        onClick={() => openRemoveModalHandler(cat.id, cat.name)}
                      >
                        удалить
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <RemoveCategoryModal
        data={removeCategoryModalData}
        callBack={closeRemoveModalHandler}
      />
    </>
  );
};
