import { useEffect } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';

import {
  legalInformationModal,
  clearLegalInformationAndClose,
} from '@/redux/features/legalInformationModalSlice';
import {
  legalInformationAPI,
  useChangeStatusMutation,
  useGetLegalInformationQuery,
} from '@/redux/api/legalInformationAPI';
import { IErrorResponse, LegalInformationStatus } from '@/types';
import { webmastersAPI } from '@/redux/api/webmastersAPI';
import { curatorsAPI } from '@/redux/api/curatorsAPI';

const { CONFIRMED, REJECTED } = LegalInformationStatus;

export const LegalInformationModal = () => {
  const dispatch = useAppDispatch();
  const { isOpen, role, userId } = useAppSelector(legalInformationModal);

  const { data, isLoading, isFetching } = useGetLegalInformationQuery(
    { role, userId },
    { skip: !isOpen }
  );

  const [updateStatus, { data: updatedData, error }] =
    useChangeStatusMutation();

  const closeModalHandler = () => {
    dispatch(clearLegalInformationAndClose());
  };

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error('Что-то пошло не так');
  }, [error]);

  useEffect(() => {
    if (!updatedData || !isOpen) return;
    dispatch(clearLegalInformationAndClose());
    if (role === 'webmaster') {
      dispatch(webmastersAPI.util.invalidateTags(['Webmasters']));
    }
    if (role === 'curator') {
      dispatch(curatorsAPI.util.invalidateTags(['Curators']));
    }
    dispatch(legalInformationAPI.util.resetApiState());

    if (updatedData.status === CONFIRMED) notify.success('Данные подтверждены');
    if (updatedData.status === REJECTED) notify.error('Данные отклонены');
    return;
  }, [updatedData, dispatch, role, isOpen, isFetching]);

  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''} `}>
      <div className='modal-box  w-11/12  max-w-2xl relative'>
        <h3 className='font-bold text-lg md:text-2xl mb-5'>
          Юридическая информация
        </h3>
        <div className='flex-col gap-5'>
          {isLoading && !data && (
            <div className='w-full flex justify-center min-h-[150px] items-center'>
              <span className='btn btn-square loading'></span>
            </div>
          )}
          {data && (
            <>
              <div className='flex justify-between w-full'>
                <span className='font-medium'>Наименование/ФИО</span>
                <span>{data.name}</span>
              </div>
              <div className='flex justify-between w-full'>
                <span className='font-medium'>ИНН</span>
                <span>{data.INN}</span>
              </div>
              <div className='flex justify-between w-full'>
                <span className='font-medium'>Pасчетный счет</span>
                <span>{data.checkingAccount}</span>
              </div>
              <div className='flex justify-between w-full'>
                <span className='font-medium'>БИК банка</span>
                <span>{data.bankBic}</span>
              </div>
              <div className='flex justify-between w-full'>
                <span className='font-medium'>Адрес регистрации</span>
                <span>{data.address}</span>
              </div>
            </>
          )}
        </div>
        <div className='modal-action'>
          <button
            disabled={data?.status === CONFIRMED}
            className='btn btn-sm btn-success'
            onClick={() => {
              userId &&
                role &&
                updateStatus({ userId, role, status: CONFIRMED });
            }}
          >
            Подтвердить
          </button>
          <button
            disabled={data?.status === REJECTED}
            className='btn btn-sm btn-warning'
            onClick={() => {
              userId &&
                role &&
                updateStatus({ userId, role, status: REJECTED });
            }}
          >
            Отклонить
          </button>
          <button className='btn btn-sm btn-error' onClick={closeModalHandler}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};
