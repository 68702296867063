import { IEditWebmasterModalState, IWebmaster, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IEditWebmasterModalState = {
  webmaster: null,
  isOpen: false,
};

export const editWebmasterModalSlice = createSlice({
  initialState,
  name: "EditWebmasterModal",
  reducers: {
    setEditWebmasterAndOpen: (state, action: PayloadAction<IWebmaster>) => {
      state.isOpen = true;
      state.webmaster = action.payload;
    },
    clearEditWebmasterAndClose: (state) => {
      state.isOpen = false;
      state.webmaster = null;
    },
  },
});

export default editWebmasterModalSlice.reducer;

export const { setEditWebmasterAndOpen, clearEditWebmasterAndClose } =
  editWebmasterModalSlice.actions;
export const editWebmasterModal = (state: RootState) =>
  state.editWebmasterModal;
