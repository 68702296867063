import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { NotificationManager as notify } from "react-notifications";

import { app, toggleAddOfferCategoryModal } from "@/redux/features/appSlice";
import { IErrorResponse } from "@/types";
import {
  useAddCategoryMutation,
  useUploadImgCategoryMutation,
} from "@/redux/api/offerCategoriesAPI";

export const AddCategoryModal = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { addOfferCategoryModalIsOpen } = useAppSelector(app);

  const [uploadImg, { data: imgData, error: imgError }] =
    useUploadImgCategoryMutation();

  const [addCategory, { error, isSuccess }] = useAddCategoryMutation();

  const dispatch = useAppDispatch();

  const [сategoryName, setCategoryName] = useState("");
  const [imgPath, setImgPath] = useState<string>();

  const cleanForm = () => {
    setCategoryName("");
    if (inputFileRef) inputFileRef.current!.value = "";
  };

  const closeModalHandler = () => {
    dispatch(toggleAddOfferCategoryModal());
    setTimeout(cleanForm, 500);
  };

  const submitHandler = () => {
    if (!сategoryName) return notify.error("Введите название категории");
    if (!imgPath) return notify.error("Добавьте изображение");
    addCategory({ name: сategoryName, img: imgPath! });
  };

  const onFileChangeCapture = () => {
    if (inputFileRef) {
      if (!inputFileRef.current?.files) return;
      const formData = new FormData();
      formData.append("file", inputFileRef.current?.files[0]);
      uploadImg(formData);
    }
  };

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(toggleAddOfferCategoryModal());
    setTimeout(cleanForm, 500);
    notify.success("Категория добавлена!");
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (!imgData) return;
    setImgPath(imgData.path);
  }, [imgData]);

  useEffect(() => {
    if (!imgError) return;
    const { data } = imgError as IErrorResponse;
    notify.error(data.message);
    if (inputFileRef) inputFileRef.current!.value = "";
  }, [imgError]);

  return (
    <div
      className={`modal ${addOfferCategoryModalIsOpen ? "modal-open" : ""} `}
    >
      <div className="modal-box w-11/12  max-w-xl relative">
        <h3 className="font-bold text-2xl mb-5">Добавить категорию</h3>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Название категории</span>
          </label>
          <input
            type="text"
            placeholder="Введите название"
            className="input input-bordered"
            value={сategoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <label className="label">
            <span className="label-text">Добавьте изображение категории</span>
          </label>
          <input
            ref={inputFileRef}
            type="file"
            onChangeCapture={onFileChangeCapture}
            className="file-input my-3 file-input-bordered  file-input-primary w-full max-w-xs"
          />
        </div>

        <div className="modal-action">
          <button className="btn btn-sm btn-success" onClick={submitHandler}>
            Добавить
          </button>
          <button className="btn btn-sm btn-error" onClick={closeModalHandler}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};
