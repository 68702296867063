import { IRemoveWebmasterModalState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IRemoveWebmasterModalState = {
  id: null,
  login: null,
  isOpen: false,
  role: null,
};

export const removeUserModalSlice = createSlice({
  initialState,
  name: "removeUserModalSlice",
  reducers: {
    setRemoveUserAndOpenModal: (
      state,
      action: PayloadAction<{
        id: number;
        login: string;
        role: "webmaster" | "curator";
      }>
    ) => {
      state.isOpen = true;
      state.login = action.payload.login;
      state.id = action.payload.id;
      state.role = action.payload.role;
    },
    clearRemoveUserAndCloseModal: (state) => {
      state.isOpen = false;
      state.login = null;
      state.id = null;
    },
  },
});

export default removeUserModalSlice.reducer;

export const { setRemoveUserAndOpenModal, clearRemoveUserAndCloseModal } =
  removeUserModalSlice.actions;
export const removeUserModal = (state: RootState) => state.removeUserModal;
