import { FormEvent, useEffect, useState } from 'react';

import {
  AddUserItem,
  AddWebmasterModal,
  Pagination,
  SortSelect,
  StatItem,
  PaidOrEarnedItem,
  WebmastersTable,
  WebmastersMobCollapse,
  RemoveUserModal,
  EditWebmasterModal,
  Search,
  LegalInformationModal,
} from '@/components';
import { useTitle } from '@/hooks/useTitile';
import {
  useGetAllWebmastersQuery,
  useGetWebmastersSearchQuery,
} from '@/redux/api/webmastersAPI';

import { IWebmaster, UsersSortType } from '@/types';
import './Webmasters.css';

export const Webmasters = () => {
  useTitle('Вебмастера');
  const [offset, setOffset] = useState(0);
  const [limit] = useState(50);
  const [webmastersCount, setWebmastersCount] = useState<number>();

  const [isSearched, setSearched] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [webmastersList, setWebmastersList] = useState<
    { total: number; webmasters: IWebmaster[] | [] } | undefined
  >(undefined);

  const [sort, setSort] = useState<UsersSortType>(UsersSortType.DATE_UP);

  const { data, isLoading, isFetching } = useGetAllWebmastersQuery(
    { offset, limit, sort },
    { skip: isSearched }
  );

  const {
    data: searchData,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
  } = useGetWebmastersSearchQuery(
    {
      query: searchValue,
    },
    {
      skip: !isSearched,
      refetchOnMountOrArgChange: true,
    }
  );

  const [loading, setloading] = useState(false);

  const sortTypeHandler = (type: UsersSortType) => {
    setOffset(0);
    setSort(type);
  };

  const searchChangeHandler = (value: string) => {
    const regex = /^[a-zA-Zа-яА-Я0-9_-]*$/;

    if (regex.test(value)) {
      setSearchValue(value);
    }
  };

  const searchFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchValue.length >= 3 && setSearched) {
      setSearched(true);
    }
  };

  const searchDeleteHandler = () => {
    if (setSearched) {
      setSearched(false);
    }

    setSearchValue('');
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isLoading || isFetching || searchIsLoading || searchIsFetching) {
      setloading(true);
    } else if (!isLoading) {
      timeout = setTimeout(() => setloading(false), 200);
    }
    return () => clearTimeout(timeout);
  }, [isFetching, isLoading, searchIsFetching, searchIsLoading]);

  useEffect(() => {
    if (isSearched) {
      setOffset(0);
    }
  }, [isSearched]);

  useEffect(() => {
    if (!isSearched && searchValue.length < 3)
      setWebmastersList({
        total: data?.total || 0,
        webmasters: data?.webmasters || [],
      });
  }, [isSearched, data, searchValue]);

  useEffect(() => {
    if (!data || !data?.total) return;
    setWebmastersCount(data.total);
  }, [data]);

  useEffect(() => {
    if (isSearched) {
      const total = searchData?.count || 0;
      const webmasters = searchData?.rows || [];

      setWebmastersList({ total, webmasters });
    }
  }, [isSearched, searchData]);

  return (
    <>
      <div className='flex justify-between flex-col sm:flex-row'>
        <h2 className='font-bold md:text-3xl text-2xl mb-5'>Вебмастера</h2>
        <div className='mb-5'>
          {' '}
          <Search
            value={searchValue}
            onChange={searchChangeHandler}
            setSearched={setSearched}
            formHandler={searchFormHandler}
            deleteHandler={searchDeleteHandler}
          />
        </div>
      </div>
      <div className='flex mb-5 gap-5 flex-col xl:flex-row'>
        <AddUserItem role='webmaster' />
        <SortSelect valueHandler={sortTypeHandler} />
        <div className='flex flex-col gap-5 md:flex-row'>
          <StatItem title='Всего вебмастеров' count={webmastersCount} />
          <PaidOrEarnedItem type='webmastersTotalEarned' />
        </div>
      </div>

      <div
        className={`webmasters-list theme-border mb-5 ${
          loading || !webmastersList?.webmasters.length ? 'empty' : ''
        } flex flex-col`}
      >
        {loading ? (
          <span className='btn btn-square loading'></span>
        ) : (
          <>
            {webmastersList?.webmasters.length === 0 && (
              <span className='text-2xl no-data-text font-thin w-full block my-auto text-center'>
                Вебмастеров не найдено
              </span>
            )}

            {webmastersList && webmastersList.webmasters.length > 0 && (
              <>
                <div className='w-full hidden md:block mt-5'>
                  <WebmastersTable
                    webmasters={webmastersList?.webmasters || []}
                  />
                </div>

                <div className='block md:hidden rounded-md overflow-hidden shadow t-border mt-5'>
                  {webmastersList &&
                    webmastersList.webmasters.map((el) => {
                      return <WebmastersMobCollapse key={el.id} data={el} />;
                    })}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {!isSearched && searchValue.length < 3 && (
        <>
          {!loading && webmastersList?.webmasters.length && data ? (
            <Pagination
              callback={(page: number) => setOffset((page - 1) * limit)}
              total={data.total}
              offset={data.offset ? data.offset : 0}
              step={limit}
            />
          ) : (
            ''
          )}
        </>
      )}

      <EditWebmasterModal />
      <RemoveUserModal />
      <AddWebmasterModal />
      <LegalInformationModal />
    </>
  );
};
