import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { adminAPI } from './api/adminAPI';
import { webmastersAPI } from './api/webmastersAPI';
import { curatorsAPI } from './api/curatorsAPI';
import { newsAPI } from './api/newsAPI';
import { offersAPI } from './api/offersAPI';
import { statsAPI } from './api/statsAPI';
import { citiesAPI } from './api/citiesAPI';
import { offerCategoriesAPI } from './api/offerCategoriesAPI';
import { paymentsAPI } from './api/paymentsAPI';
import { leadsAPI } from './api/leadsAPI';

import appReducer from './features/appSlice';
import adminReducer from './features/adminSlice';
import editWebmasterModalReducer from './features/editWebmasterModalSlice';
import legalInformationModalReducer from './features/legalInformationModalSlice';
import editCuratorModalReducer from './features/editCuratorModalSlice';
import removeUserModalReducer from './features/removeUserModalSlice';
import confirmReqistrationModalReducer from './features/confirmReqistrationModalSlice';
import paymentModalReducer from './features/paymentModalSlice';
import leadsReducer from './features/leadsSlice';
import leadsModalsReducer from './features/leadsModalsSlice';
import searchesReducer from './features/searchesSlice';
import { registerRequestAPI } from './api/registerRequestAPI';
import { legalInformationAPI } from './api/legalInformationAPI';

export const rootReducer = combineReducers({
  app: appReducer,
  admin: adminReducer,
  editWebmasterModal: editWebmasterModalReducer,
  legalInformationModal: legalInformationModalReducer,
  editCuratorModal: editCuratorModalReducer,
  removeUserModal: removeUserModalReducer,
  confirmReqistrationModal: confirmReqistrationModalReducer,
  paymentModal: paymentModalReducer,
  leads: leadsReducer,
  leadsModals: leadsModalsReducer,
  searches: searchesReducer,
  [adminAPI.reducerPath]: adminAPI.reducer,
  [webmastersAPI.reducerPath]: webmastersAPI.reducer,
  [curatorsAPI.reducerPath]: curatorsAPI.reducer,
  [newsAPI.reducerPath]: newsAPI.reducer,
  [offersAPI.reducerPath]: offersAPI.reducer,
  [citiesAPI.reducerPath]: citiesAPI.reducer,
  [offerCategoriesAPI.reducerPath]: offerCategoriesAPI.reducer,
  [statsAPI.reducerPath]: statsAPI.reducer,
  [paymentsAPI.reducerPath]: paymentsAPI.reducer,
  [leadsAPI.reducerPath]: leadsAPI.reducer,
  [registerRequestAPI.reducerPath]: registerRequestAPI.reducer,
  [legalInformationAPI.reducerPath]: legalInformationAPI.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        adminAPI.middleware,
        webmastersAPI.middleware,
        curatorsAPI.middleware,
        newsAPI.middleware,
        offersAPI.middleware,
        citiesAPI.middleware,
        offerCategoriesAPI.middleware,
        statsAPI.middleware,
        paymentsAPI.middleware,
        leadsAPI.middleware,
        registerRequestAPI.middleware,
        legalInformationAPI.middleware,
      ]),
  });
};
