import { ICurator, IEditCuratorModalState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IEditCuratorModalState = {
  curator: null,
  isOpen: false,
};

export const editCuratorModalSlice = createSlice({
  initialState,
  name: "editCuratorModal",
  reducers: {
    setEditCuratorAndOpenModal: (state, action: PayloadAction<ICurator>) => {
      state.isOpen = true;
      state.curator = action.payload;
    },
    clearEditCuratorAndCloseModal: (state) => {
      state.isOpen = false;
      state.curator = null;
    },
  },
});

export default editCuratorModalSlice.reducer;

export const { setEditCuratorAndOpenModal, clearEditCuratorAndCloseModal } =
  editCuratorModalSlice.actions;
export const editCuratorModal = (state: RootState) => state.editCuratorModal;
