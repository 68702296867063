import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { ILead, ILeadsResponse } from "@/types";

export const leadsAPI = createApi({
  reducerPath: "leadsAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/leads/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Leads"],
  endpoints: (builder) => ({
    getLeads: builder.query<
      ILeadsResponse,
      {
        offset: number;
        limit: number;
        cityId: number | undefined;
        categoryId: number | undefined;
        status: string | undefined;
        start: string | undefined;
        end: string | undefined;
        lossReason: string | undefined;
      }
    >({
      query: (params) => ({
        url: "all",
        method: "GET",
        params: {
          offset: params?.offset,
          limit: params?.limit,
          cityId: params?.cityId,
          categoryId: params?.categoryId,
          status: params?.status,
          start: params?.start,
          end: params?.end,
          lossReason: params?.lossReason,
        },
      }),
      providesTags: (result) => ["Leads"],
    }),

    approveManually: builder.mutation<ILeadsResponse, { id: number }>({
      query: (body) => ({
        url: "approve-manually",
        method: "PATCH",
        body,
      }),

      invalidatesTags: ["Leads"],
    }),

    inWorkManually: builder.mutation<ILeadsResponse, { id: number }>({
      query: (body) => ({
        url: "in-work-manually",
        method: "PATCH",
        body,
      }),

      invalidatesTags: ["Leads"],
    }),

    getLeadsSearch: builder.query<
      { count: number; rows: ILead[] },
      { query: string; webmasterId?: number }
    >({
      query: (params) => ({
        url: "search",
        method: "GET",
        params,
      }),
    }),

    rejectManually: builder.mutation<ILeadsResponse, { id: number }>({
      query: (body) => ({
        url: "reject-manually",
        method: "PATCH",
        body,
      }),

      invalidatesTags: ["Leads"],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useApproveManuallyMutation,
  useGetLeadsSearchQuery,
  useInWorkManuallyMutation,
  useRejectManuallyMutation,
} = leadsAPI;
