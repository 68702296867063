import { NotificationManager as notify } from "react-notifications";
import { DatePicker } from "@/components/DatePicker/DatePicker";
import { compareDates } from "@/utils";
import { PaymentsSortType } from "@/types";

type IProps = {
  filters: {
    sort?: PaymentsSortType;
    startDate?: string | null;
    endDate?: string | null;
  };

  setFilters: React.Dispatch<
    React.SetStateAction<{
      sort?: PaymentsSortType | undefined;
      startDate?: string | null | undefined;
      endDate?: string | null | undefined;
    }>
  >;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
};

export const SelectPaymentsDates: React.FC<IProps> = ({
  filters,
  setFilters,
  setOffset,
}) => {
  const getDateString = (date: Date) => {
    return `${date?.getFullYear()}/${date?.getMonth() + 1}/${date?.getDate()}`;
  };

  const startDateHandler = (date: Date | null) => {
    setOffset(0);

    if (filters.endDate && date) {
      const res = compareDates(getDateString(date), filters.endDate);
      if (!res) return notify.error("Ошибочно выбрана дата");
    }
    setFilters((prev) => ({
      ...prev,
      startDate: date ? getDateString(date) : null,
    }));
  };

  const endDateHandler = (date: Date | null) => {
    setOffset(0);

    if (filters.startDate && date) {
      const res = compareDates(filters.startDate, getDateString(date));
      if (!res) return notify.error("Ошибочно выбрана дата");
    }
    setFilters((prev) => ({
      ...prev,
      endDate: date ? getDateString(date) : null,
    }));
  };

  const clearDates = () => {
    setFilters((prev) => ({
      ...prev,
      startDate: undefined,
      endDate: undefined,
    }));
  };

  return (
    <div className="bg-base-100 flex-grow theme-border rounded-md shadow px-6 py-4 md:flex gap-5  md:items-end">
      <div>
        <label className="label">
          <span className="label-text">Начальная дата</span>
        </label>
        <DatePicker
          date={filters.startDate ? new Date(filters.startDate) : null}
          dateHandler={startDateHandler}
        />
      </div>
      <div>
        <label className="label">
          <span className="label-text">Конечная дата</span>
        </label>
        <DatePicker
          date={filters.endDate ? new Date(filters.endDate) : null}
          dateHandler={endDateHandler}
        />
      </div>
      <div>
        <button
          onClick={clearDates}
          className="btn w-full md:w-[100px] md:mt-0 mt-5 btn-error btn-sm lowercase"
        >
          сбросить
        </button>
      </div>
    </div>
  );
};
