import { useEffect, useState } from "react";

export const useTitle = (title: string) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [document_title, setTitle] = useState(capitalizeFirstLetter(title));
  useEffect(() => {
    document.title = capitalizeFirstLetter(document_title + " | Администратор");
  }, [document_title]);

  return [setTitle];
};
