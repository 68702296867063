import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IOfferCategory } from "@/types";

export const offerCategoriesAPI = createApi({
  reducerPath: "offerCategoriesAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/offer-categories/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    getAllCategories: builder.query<IOfferCategory[], null>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: (result) => ["Categories"],
    }),
    removeCategory: builder.mutation<{ message: string }, { id: number }>({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),

    uploadImgCategory: builder.mutation<{ path: string }, any>({
      query: (body) => ({
        url: "upload",
        method: "POST",
        body,
      }),
    }),
    addCategory: builder.mutation<
      IOfferCategory,
      Pick<IOfferCategory, "name" | "img">
    >({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useRemoveCategoryMutation,
  useUploadImgCategoryMutation,
  useAddCategoryMutation,
} = offerCategoriesAPI;
