import { NotificationManager as notify } from 'react-notifications';
import { useEffect, useState } from 'react';

import { useLoginMutation } from '@/redux/api/adminAPI';
import { IErrorResponse } from '@/types';
import { useTitle } from '@/hooks/useTitile';
import { EyeIcon, EyeOffIcon, Logo } from '@/components';
import { routing, tokenLSKey } from '@/constants';

import './Login.css';

export const Login = () => {
  useTitle('Вход администратора');
  const [loginAdmin, { error, data, isLoading }] = useLoginMutation();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordType, setPasswordType] = useState(true);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (!login || !password) return notify.error('Введите логин и пароль');
    loginAdmin({ login, password });
  };

  useEffect(() => {
    if (localStorage.getItem(tokenLSKey)) {
      window.location.href = `/${routing.CABINET}/${routing.LEADS}`;
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    localStorage.setItem(tokenLSKey, data.accessToken);
    window.location.href = `/${routing.CABINET}/${routing.LEADS}`;
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    localStorage.removeItem(tokenLSKey);
    if (data) return notify.error(data.message);
    return notify.error('Что-то пошло не так');
  }, [error]);

  return (
    <div className='page login-page bg-base-200 flex items-center justify-center'>
      <div className='login-form-wrapper p-3 md:p-0'>
        <div className='bg-base-100 theme-border shadow  rounded-md p-10 pt-4 relative'>
          <div className='login-logo mx-auto'>
            <Logo />
          </div>
          <div className='lg:text-xl brand-color font-bold text-center mb-4'>
            TECHLEAD CPA
          </div>

          <p className='text-lg mb-1'>Вход для администратора</p>
          <p className='sub-text '>
            Для входа введите данные от вашего аккаунта
          </p>
          <form className='login-form mt-4' onSubmit={submitHandler}>
            <input
              type='text'
              placeholder='Логин'
              className='input input-bordered input-primary w-full mb-5'
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <div className='relative'>
              <input
                type={isPasswordType ? 'password' : 'text'}
                placeholder='Пароль'
                className='input input-bordered  input-primary w-full mb-5'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className='cursor-pointer noselect eye absolute top-[15px] right-2'
                onClick={() => setPasswordType(!isPasswordType)}
              >
                {isPasswordType ? <EyeIcon /> : <EyeOffIcon />}
              </span>
            </div>
            <button
              className={`btn ${isLoading ? 'loading' : ''} btn-primary w-full`}
            >
              Войти
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
