import { IRemoveOfferModalData } from "@/types";
type Props = {
  data: IRemoveOfferModalData;
  callBack: (status: boolean) => void;
};

export const RemoveOfferModal: React.FC<Props> = ({ data, callBack }) => {
  const cancelRemoveHandler = () => callBack(false);
  const removeHandler = (id: number) => {
    callBack(true);
  };

  return (
    <div className={`modal ${data.isOpen ? "modal-open" : ""}`}>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Удаление оффера</h3>
        <p className="py-4">
          Вы действительно хотите удалить оффер <br /> <b>{data.name}</b> ?
        </p>
        <div className="modal-action">
          <button
            className="btn btn-sm btn-primary"
            onClick={cancelRemoveHandler}
          >
            отмена
          </button>
          <button
            className="btn btn-sm btn-error"
            onClick={() => removeHandler(data.id!)}
          >
            Да
          </button>
        </div>
      </div>
    </div>
  );
};
