import { ILead, LeadStatus } from '@/types';

import { LeadStatusBadge, CheckIcon, WorkIcon, CloseIcon } from '@/components';
import {
  setErrorOpen,
  setId,
  setSuccessOpen,
  setWorkOpen,
} from '@/redux/features/leadsModalsSlice';
import { useAppDispatch } from '@/hooks/useRedux';

type Props = {
  leads: ILead[];
};

export const LeadsTable: React.FC<Props> = ({ leads }) => {
  const dispatch = useAppDispatch();

  return (
    <div className='overflow-x-auto '>
      <table className='table w-full  table-compact'>
        <thead>
          <tr>
            <th>Дата</th>
            <th>ID</th>
            <th>AMO ID</th>
            <th>Вебмастер</th>
            <th>Категория</th>
            <th>Город</th>
            <th>Адрес</th>
            <th>Тел. клиента</th>
            <th>Телефония</th>

            <th>Выплата</th>
            <th>Cтатус</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => {
            return (
              <tr className='hover' key={lead.id}>
                <td>
                  {new Date(lead.createdAt)
                    .toLocaleDateString(navigator.language, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(',', '')}
                </td>
                <td>{lead.id}</td>
                <td>{lead.amoId}</td>
                <td>
                  <div className='tooltip' data-tip={`ID: ${lead.webmasterId}`}>
                    {lead.webmasterLogin}
                  </div>
                </td>
                <td>{lead.categoryName ? lead.categoryName : '----'}</td>
                <td>{lead.cityName ? lead.cityName : '----'}</td>
                <td>{lead.address ? lead.address : '----'}</td>
                <td>{lead.leadPhone}</td>
                <td>
                  {lead.trackingPhone === 'p_manually'
                    ? 'Вручную (Частное лицо)'
                    : lead.trackingPhone === 'c_manually'
                    ? 'Вручную (Компания)'
                    : lead.trackingPhone === 'c_api'
                    ? 'API (Компания)'
                    : lead.trackingPhone === 'p_api'
                    ? 'API (Частное лицо)'
                    : lead.trackingPhone}
                </td>

                <td>
                  {lead.sum
                    ? lead.status === LeadStatus.APPROVED ||
                      lead.status === LeadStatus.IN_WORK
                      ? lead.sum + ' K'
                      : '----'
                    : '----'}
                </td>
                <td>
                  <LeadStatusBadge
                    comment={lead.comment}
                    status={lead.status}
                  />
                </td>
                <td>
                  <div className='flex items-center gap-2 pr-5'>
                    <div className='tooltip' data-tip='Подтвердить'>
                      <button
                        className='btn btn-xs btn-success lowercase'
                        onClick={() => {
                          dispatch(setId(lead.id));
                          dispatch(setSuccessOpen(true));
                        }}
                        disabled={
                          lead.status === LeadStatus.APPROVED ||
                          lead.status === LeadStatus.REJECTED
                            ? true
                            : false
                        }
                      >
                        <CheckIcon />
                      </button>
                    </div>
                    <div className='tooltip' data-tip='В работу'>
                      <button
                        className='btn btn-xs btn-primary lowercase'
                        onClick={() => {
                          dispatch(setId(lead.id));
                          dispatch(setWorkOpen(true));
                        }}
                        disabled={
                          lead.status === LeadStatus.APPROVED ||
                          lead.status === LeadStatus.REJECTED
                            ? true
                            : lead.status === LeadStatus.IN_WORK
                            ? true
                            : false
                        }
                      >
                        <WorkIcon />
                      </button>
                    </div>
                    <div className='tooltip' data-tip='Отклонить'>
                      <button
                        className='btn btn-xs btn-error lowercase'
                        onClick={() => {
                          dispatch(setId(lead.id));
                          dispatch(setErrorOpen(true));
                        }}
                        disabled={
                          lead.status === LeadStatus.APPROVED ||
                          lead.status === LeadStatus.REJECTED
                            ? true
                            : false
                        }
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
