import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { tokenLSKey } from '@/constants';
import { FinancesSearchType, IPaymentsResponse } from '@/types';

export const paymentsAPI = createApi({
  reducerPath: 'paymentsAPI',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/payments/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ['Payments'],
  endpoints: (builder) => ({
    getWaitingPayments: builder.query<
      IPaymentsResponse,
      {
        limit: number;
        offset: number;
        role: string;
        sort?: string;
        start?: string;
        end?: string;
      }
    >({
      query: (params) => ({
        url: 'not-completed',
        method: 'GET',
        params,
      }),
      providesTags: (result) => ['Payments'],
    }),
    getCompletedPayments: builder.query<
      IPaymentsResponse,
      {
        limit: number;
        offset: number;
        role?: string;
        sort?: string;
        start?: string;
        end?: string;
      }
    >({
      query: (params) => ({
        url: 'completed',
        method: 'GET',
        params,
      }),
      providesTags: (result) => ['Payments'],
    }),
    setComleted: builder.mutation<
      any,
      {
        webmasterId?: number;
        curatorId?: number;
        paymentId: number;
        sum: number;
      }
    >({
      query: (body) => ({
        url: 'complete',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Payments'],
    }),

    getSearchedPayments: builder.query<
      IPaymentsResponse,
      { query: string; type: FinancesSearchType }
    >({
      query: (params) => ({
        url: 'search',
        method: 'GET',
        params,
      }),

      providesTags: (result) => ['Payments'],
    }),
  }),
});

export const {
  useGetWaitingPaymentsQuery,
  useSetComletedMutation,
  useGetCompletedPaymentsQuery,
  useGetSearchedPaymentsQuery,
} = paymentsAPI;
