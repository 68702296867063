import { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { useEditWebmasterMutation } from "@/redux/api/webmastersAPI";
import { IErrorResponse, ITelephony, ITelephonyInputs } from "@/types";
import { TelephonyInputs } from "@/components/Webmasters/TelephonyInputs/TelephonyInputs";

import {
  editWebmasterModal,
  clearEditWebmasterAndClose,
} from "@/redux/features/editWebmasterModalSlice";

import {
  validateLogin,
  validatePassword,
  validateTelegram,
  validatePhone,
  validateTelephony,
} from "@/utils";
import { CuratorsSelect } from "@/components/Curators/CuratorsSelect/CuratorsSelect";

export const EditWebmasterModal = () => {
  const { isOpen, webmaster } = useAppSelector(editWebmasterModal);
  const [editWebmaster, { isSuccess, error }] = useEditWebmasterMutation();

  const dispatch = useAppDispatch();

  const [login, setLogin] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [workTelegram, setWorkTelegram] = useState("");
  const [password, setPassword] = useState("");
  const [curatorId, setCuratorId] = useState<number | null>(null);
  const [telephony, setTelephony] = useState<ITelephonyInputs[]>([]);

  const closeModalHandler = () => {
    dispatch(clearEditWebmasterAndClose());
    setTimeout(cleanForm, 500);
  };

  const changeCuratorIdHandler = (id: number) => setCuratorId(id || null);

  const telephonyInputChangeHandler = (
    id: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    let data = [...telephony];
    data[id] = {
      ...data[id],
      [name]: value,
    };
    setTelephony(data);
  };

  const addTelephonyInput = () => {
    setTelephony((prev) => [...prev, { name: "", number: "", type: "none" }]);
  };

  const removeTelephonyInput = () => {
    setTelephony((prev) => {
      if (prev.length === 1) return [];
      const arr = [...prev];
      arr.pop();
      return [...arr];
    });
  };

  const cleanForm = () => {
    setLogin("");
    setPassword("");
    setWorkPhone("");
    setWorkTelegram("");
    setCuratorId(null);
    setTelephony([]);
  };

  const submitHandler = () => {
    const loginError = validateLogin(login);
    if (loginError) return notify.error(loginError);

    if (password) {
      const pwdError = validatePassword(password);
      if (pwdError) return notify.error(pwdError);
    }

    if (workTelegram) {
      const tgError = validateTelegram(workTelegram);
      if (tgError) return notify.error(tgError);
    }

    if (workPhone) {
      const phoneError = validatePhone(workPhone);
      if (phoneError) return notify.error(phoneError);
    }

    if (!workTelegram && !workPhone) {
      return notify.error("Введите телефон или телеграм");
    }

    if (telephony.length) {
      const telephonyError = validateTelephony(telephony);
      if (telephonyError) return notify.error(telephonyError);
    }

    editWebmaster({
      id: webmaster!.id,
      login,
      password: password || null,
      workPhone,
      workTelegram,
      telephony: telephony as ITelephony[],
      curatorId: curatorId || null,
    });
  };

  useEffect(() => {
    if (webmaster) {
      setLogin(webmaster.login);
      setTelephony(webmaster.telephony);
      webmaster.workPhone && setWorkPhone(webmaster.workPhone);
      webmaster.workTelegram && setWorkTelegram(webmaster.workTelegram);
      webmaster.curatorId && setCuratorId(webmaster.curatorId);
    }
  }, [webmaster]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error("Что-то пошло не так");
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(clearEditWebmasterAndClose());
    setTimeout(cleanForm, 500);
    notify.success("Вебмастер отредактирован!");
  }, [isSuccess, dispatch]);

  return (
    <div className={`modal ${isOpen ? "modal-open" : ""} `}>
      <div className="modal-box  w-11/12  max-w-2xl relative">
        <h3 className="font-bold text-lg md:text-2xl mb-5">
          Редактировать вебмастера
        </h3>
        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Логин</span>
              </label>
              <input
                type="text"
                placeholder="Введите логин"
                className="input input-bordered input-sm"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Пароль{" "}
                  <span className="text-error">
                    (текущий пароль будет сброшен)
                  </span>
                </span>
              </label>
              <input
                type="text"
                placeholder="Введите пароль"
                className="input input-bordered input-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Телефон</span>
              </label>
              <input
                type="text"
                placeholder="Введите телефон"
                className="input input-bordered input-sm"
                value={workPhone}
                onChange={(e) => setWorkPhone(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Телеграм</span>
              </label>
              <input
                type="text"
                placeholder="Введите телеграм"
                className="input input-bordered input-sm"
                value={workTelegram}
                onChange={(e) => setWorkTelegram(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="w-1/2 mt-3 pr-[10px]">
          <CuratorsSelect
            valueHandler={changeCuratorIdHandler}
            selectedId={curatorId}
          />
        </div>

        <TelephonyInputs
          inputs={telephony}
          onChange={telephonyInputChangeHandler}
          add={addTelephonyInput}
          remove={removeTelephonyInput}
        />

        <div className="modal-action">
          <button className="btn btn-sm btn-success" onClick={submitHandler}>
            Сохранить
          </button>
          <button className="btn btn-sm btn-error" onClick={closeModalHandler}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};
