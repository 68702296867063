import { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { IErrorResponse } from "@/types";

import {
  validateLogin,
  validatePassword,
  validateTelegram,
  validatePhone,
} from "@/utils";

import {
  clearEditCuratorAndCloseModal,
  editCuratorModal,
} from "@/redux/features/editCuratorModalSlice";
import { useEditCuratorMutation } from "@/redux/api/curatorsAPI";

export const EditCuratorModal = () => {
  const { isOpen, curator } = useAppSelector(editCuratorModal);
  const [editCurator, { isSuccess, error }] = useEditCuratorMutation();
  const dispatch = useAppDispatch();

  const [login, setLogin] = useState("");
  const [name, setName] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [workTelegram, setWorkTelegram] = useState("");
  const [password, setPassword] = useState("");

  const closeModalHandler = () => {
    dispatch(clearEditCuratorAndCloseModal());
    setTimeout(cleanForm, 500);
  };

  const cleanForm = () => {
    setLogin("");
    setName("");
    setPassword("");
    setWorkPhone("");
    setWorkTelegram("");
  };

  const submitHandler = () => {
    const loginError = validateLogin(login);
    if (loginError) return notify.error(loginError);

    if (password) {
      const pwdError = validatePassword(password);
      if (pwdError) return notify.error(pwdError);
    }

    if (workTelegram) {
      const tgError = validateTelegram(workTelegram);
      if (tgError) return notify.error(tgError);
    }

    if (workPhone) {
      const phoneError = validatePhone(workPhone);
      if (phoneError) return notify.error(phoneError);
    }

    if (!workTelegram && !workPhone) {
      return notify.error("Введите телефон или телеграм");
    }

    editCurator({
      id: curator!.id,
      login,
      name,
      password: password || null,
      workPhone,
      workTelegram,
    });
  };

  useEffect(() => {
    if (curator) {
      setLogin(curator.login);
      setName(curator.name);
      curator.workPhone && setWorkPhone(curator.workPhone);
      curator.workTelegram && setWorkTelegram(curator.workTelegram);
    }
  }, [curator]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error("Что-то пошло не так");
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(clearEditCuratorAndCloseModal());
    setTimeout(cleanForm, 500);
    notify.success("Куратор отредактирован!");
  }, [isSuccess, dispatch]);

  return (
    <div className={`modal ${isOpen ? "modal-open" : ""} `}>
      <div className="modal-box  w-11/12  max-w-2xl relative">
        <h3 className="font-bold text-lg md:text-2xl mb-5">
          Редактировать куратора
        </h3>
        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Логин</span>
              </label>
              <input
                type="text"
                placeholder="Введите логин"
                className="input input-bordered input-sm"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Имя</span>
              </label>
              <input
                type="text"
                placeholder="Введите имя"
                className="input input-bordered input-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Пароль{" "}
                  <span className="text-error">
                    (текущий пароль будет сброшен)
                  </span>
                </span>
              </label>
              <input
                type="text"
                placeholder="Введите пароль"
                className="input input-bordered input-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Телефон</span>
              </label>
              <input
                type="text"
                placeholder="Введите телефон"
                className="input input-bordered input-sm"
                value={workPhone}
                onChange={(e) => setWorkPhone(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Телеграм</span>
              </label>
              <input
                type="text"
                placeholder="Введите телеграм"
                className="input input-bordered input-sm"
                value={workTelegram}
                onChange={(e) => setWorkTelegram(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="modal-action">
          <button className="btn btn-sm btn-success" onClick={submitHandler}>
            Сохранить
          </button>
          <button className="btn btn-sm btn-error" onClick={closeModalHandler}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};
