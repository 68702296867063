import { LegalInformationStatus } from '@/types';

const { MODERATION, REJECTED, CONFIRMED } = LegalInformationStatus;

type Props = {
  status: LegalInformationStatus | undefined;
};

export const LegalInformationStatusBadge: React.FC<Props> = ({ status }) => {
  let className =
    status === undefined
      ? 'badge-warning'
      : status === MODERATION
      ? 'badge-info'
      : status === CONFIRMED
      ? 'badge-success'
      : status === REJECTED
      ? 'badge-error'
      : '';

  className += ' badge lowercas font-normal';

  const text =
    status === undefined
      ? 'демо'
      : status === MODERATION
      ? 'на проверке'
      : status === CONFIRMED
      ? 'активен'
      : status === REJECTED
      ? 'отклонен'
      : '';

  return <span className={className}>{text}</span>;
};
