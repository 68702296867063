import { useGetCuratorsListQuery } from "@/redux/api/curatorsAPI";

import { useEffect, useState } from "react";

type Props = {
  valueHandler: (cityId: number) => void;
  selectedId?: number | null;
};

export const CuratorsSelect: React.FC<Props> = ({
  valueHandler,
  selectedId,
}) => {
  const { data, isLoading, isFetching } = useGetCuratorsListQuery(null);

  const [selected, setSelected] = useState<number>(0);

  const loading = isLoading || isFetching;

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(parseInt(event.target.value));
    valueHandler(parseInt(event.target.value));
  };

  useEffect(() => {
    if (selectedId) {
      setSelected(selectedId || 0);
    }
    if (data?.length) {
      let issetCurator: boolean = false;
      data.forEach((c) => {
        if (c.id === selectedId) issetCurator = true;
      });
      if (!issetCurator) valueHandler(0);
    } else {
      valueHandler(0);
    }
  }, [selectedId, data, valueHandler]);

  return (
    <>
      <label className="label">
        <span className="label-text">Куратор</span>
      </label>
      {loading ? (
        <select
          defaultValue="loading"
          className="select select-sm w-full select-bordered max-w-xs "
        >
          <option value="loading">Загрузка...</option>
        </select>
      ) : (
        <>
          {data && data.length ? (
            <>
              <select
                className="select select-sm select-bordered w-full"
                value={selected}
                onChange={changeSelect}
              >
                <option value={0}>Без куратора</option>

                {data?.map((curator) => {
                  return (
                    <option value={curator.id} key={curator.id}>
                      {curator.login} | {curator.name}
                    </option>
                  );
                })}
              </select>
            </>
          ) : (
            <select className="select select-sm w-full select-bordered max-w-xs ">
              <option disabled>Нет кураторов</option>
            </select>
          )}
        </>
      )}
    </>
  );
};
